<template>
  <div class="login-form">
    <h1 v-if="!submitted" class="mb-45" v-html="currentStep?.title"></h1>
    <form v-if="!submitted" class="w-100" @submit.prevent="submitForm">
      <div v-if="currentStepIndex === 1" class="mb-5">
        <v-select
            v-model="form.registrationType"
            :error-messages="registrationTypeErrors"
            :label="$store.state.translations['registerPage.Placeholders.SelectOption']"
            :items="['Mentor', 'Mentee']"
            variant="outlined"
        ></v-select>
      </div>
      <div v-if="currentStepIndex === 2" class="mb-5">
        <v-text-field
            v-model="form.name"
            :error-messages="nameErrors"
            :label="$store.state.translations['registerPage.Placeholders.Name']"
            type="email"
            variant="outlined"
        ></v-text-field>
      </div>
      <div v-if="currentStepIndex === 3" class="mb-5">
        <v-text-field
            v-model="form.email"
            :error-messages="emailErrors"
            :label="$store.state.translations['registerPage.Placeholders.Email']"
            type="email"
            variant="outlined"
        ></v-text-field>
      </div>
      <div v-if="currentStepIndex === 4" class="mb-5">
        <span class="d-block mb-6">Hasło powinno zawierać co najmniej 10 znaków, wielką, małą literę, cyfrę i znak specjalny.</span>
        <v-text-field
            v-model="form.password"
            :error-messages="passwordErrors"
            :label="$store.state.translations['registerPage.Placeholders.Password']"
            class="mb-5"
            type="password"
            variant="outlined"
        ></v-text-field>
        <v-text-field
            v-model="form.confirmPassword"
            :error-messages="confirmPasswordErrors"
            :label="$store.state.translations['registerPage.Placeholders.ConfirmPassword']"
            type="password"
            variant="outlined"
        ></v-text-field>
      </div>
      <div class="btn-wrapper">
        <v-btn v-if="!showNextButton" class="pa-2 w-100" color="primary" size="large" type="submit">
          {{ $store.state.translations['registerPage.Buttons.Register'] }}
        </v-btn>
        <v-btn v-if="showNextButton" class="pa-2 w-100" color="primary" size="large" @click="next">
          {{ $store.state.translations['registerPage.Buttons.Next'] }}
        </v-btn>
        <v-btn v-if="showPreviousButton" class="pa-2 w-100" color="primary" size="large" variant="outlined"
               @click="prev">
          {{ $store.state.translations['portal.Placeholders.Previous'] }}
        </v-btn>
      </div>
    </form>

    <div class="bh_divider">
      {{ currentStepIndex }}/{{ steps.length }}
    </div>

  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, requiredIf, sameAs} from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "RegisterForm",
  props: {
    model: Object,
  },
  data: () => ({
    v$: useVuelidate(),
    currentStepIndex: 1,
    steps: [],
    submitted: false,
    responseErrors: [],
    form: {
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      culture: null,
      registrationType: null
    },
  }),
  mounted() {
    this.steps.push({title: this.model.typeTitle,});
    this.steps.push({title: this.model.nameTitle,});
    this.steps.push({title: this.model.emailTitle,});
    this.steps.push({title: this.model.passwordTitle,});
    this.form.culture = this.model.culture;
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex - 1];
    },
    showPreviousButton() {
      return this.currentStepIndex > 1;
    },
    showNextButton() {
      return this.currentStepIndex < this.steps.length;
    },
    emailErrors() {
      const errors = [];
      if (!this.v$.form.email.$dirty)
        return errors;
      this.v$.form.email.email.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.InvalidEmail"]);
      this.v$.form.email.required.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.RequiredField"]);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.v$.form.password.$dirty)
        return errors;
      this.v$.form.password.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.RequiredField"]);
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.v$.form.confirmPassword.$dirty)
        return errors;
      this.v$.form.confirmPassword.required.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.RequiredField"]);
      this.v$.form.confirmPassword.sameAs.$invalid && errors.push(this.$store.state.translations["registerPage.Errors.InvalidConfirmPassword"]);
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.v$.form.name.$dirty)
        return errors;
      this.v$.form.name.required.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.RequiredField"]);
      return errors;
    },
    registrationTypeErrors() {
      const errors = [];
      if (!this.v$.form.registrationType.$dirty)
        return errors;
      this.v$.form.registrationType.required.$invalid && errors.push(this.$store.state.translations["portal.ValidationMessages.RequiredField"]);
      return errors;
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: requiredIf(this.currentStepIndex >= 2)
        },
        email: {
          required: requiredIf(this.currentStepIndex >= 3),
          email
        },
        password: {
          required: requiredIf(this.currentStepIndex >= 4)
        },
        confirmPassword: {
          required: requiredIf(this.currentStepIndex >= 4),
          sameAs: sameAs(this.form.password)
        },
        registrationType: {
          required: requiredIf(this.currentStepIndex >= 1)
        }
      }
    };
  },
  methods: {
    async prev() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect)
        return;
      this.v$.$reset();
      this.currentStepIndex = this.currentStepIndex - 1;
    },
    async next() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect)
        return;
      this.v$.$reset();
      this.currentStepIndex = this.currentStepIndex + 1;
    },
    async submitForm() {
      this.v$.$reset();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$errorToast(this.$store.state.translations["portal.ValidationMessages.InvalidForm"]);
        return;
      }
      let current = this;
      current.$setBusy(true);
      axios({
        method: "POST",
        url: current.model.endpoint,
        data: {...current.form},
      }).then(function (response) {
        if (response.status === 200) {
          current.v$.$reset();
          current.$setBusy(false);
          current.form = {
            name: null,
            email: null,
            password: null,
            confirmPassword: null,
            culture: null,
            registrationType: null
          };
          current.currentStepIndex = 1;
          current.$successToast(current.$store.state.translations['registerPage.Account.Created']);
          setTimeout(function(){
            window.location.href = "/";
          }, 3000);
          
        }
      }).catch(function (error) {
        if (error.response.data.errors) {
          Object.entries(error.response.data.errors).map((item) => {
            item[1].forEach((error) => {
              current.$errorToast(current.$store.state.translations[error]);
            });
          });
        }
        if (error.response.data.detail) {
          current.$errorToast(current.$store.state.translations[error.response.data.detail]);
        }
        current.$setBusy(false);
      });
    }
  }
}
</script>